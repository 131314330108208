<template>
    <div class="right_main">
        <!-- 页面标题  按钮部分 -->
        <div class="top_btn">
            <!-- 页面标题部分 -->
            <div class="page_name">
                <div class="sig"></div>
                <div class="sig_name">客户管理列表</div>
                <!-- 筛选下拉框 -->
                <div class="sig_sel">
                    <el-select v-model="comp_id" placeholder="公司名称" clearable size="mini" @change="initData">
                        <el-option v-for="item in comp_arr" :key="item.uid" :label="item.partner_uid" :value="item.uid">
                        </el-option>
                    </el-select>
                    <el-input v-model="comp_name" prefix-icon="el-icon-search" placeholder="客户名称" clearable
                        @change="initData" size="mini" style="margin-left:16px"></el-input>
                </div>
            </div>
            <!-- 按钮部分 -->
            <div class="btn_cl">
                <el-button @click="loadtion">导入</el-button>
                <el-button @click="addtion">添加</el-button>
                <el-button @click="deltion">删除</el-button>
            </div>
        </div>
        <!--表格部分  -->
        <div class="tab_list">
            <el-table ref="multipleTable" @selection-change="handleSelectionChange" :data="tableData"
                style="width: 100%" :header-cell-style="{color:'#000000',font:'14px 600',borderBottom:'none'}">
                <el-table-column type="selection" align="center">
                </el-table-column>
                <el-table-column prop="uid" v-if="show=false">
                </el-table-column>
                <el-table-column prop="partner" label="公司名称" min-width="120">
                </el-table-column>
                <el-table-column prop="name" label="客户名称">
                </el-table-column>
                <el-table-column prop="servicer_id" label="业务员">
                </el-table-column>
                <el-table-column prop="level" label="等级">
                </el-table-column>
                <!-- <el-table-column prop="status" label="手机号" align="center">
                </el-table-column> -->
                <el-table-column prop="area" label="地区">
                </el-table-column>
                <el-table-column label="设置">
                    <template slot-scope="scope">
                        <span style="margin-right:16px" @click="handle_edit(scope.row.uid)">编辑</span>
                        <span @click="handle_del(scope.row.uid)">删除</span>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <!-- 分页部分 -->
        <div v-if="page_show">
            <Paging :count="count" :initData="initData" @changePage="handle_changePage"></Paging>
        </div>
        <!-- 删除弹框 -->
        <el-dialog :visible.sync="del_dialog" width="567px" :before-close="handleClose" @close="remove_Fn">
            <!-- 标题 -->
            <div class="tipes">
                <span>删除</span>
            </div>
            <div class="del_content">{{del_tip}}</div>
            <!-- 提交 取消 按钮 -->
            <div class="formBtn">
                <el-button class="del_Reset" @click="remove_Fn">取消</el-button>
                <el-button class="del_confirm" @click="Subimt_del">确认</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import Paging from '@/components/Paging';
import { getCustomerApi, deletCustomerApi } from '@/api'
export default {
    components: {
        Paging,
    },
    data () {
        return {
            // 登录用户id
            user_id: '',
            // 有没有下级
            lower: '',
            // 数据id
            id: '',
            // 下级id  没选就是用户id  选了就是下拉框选中的值
            down_id: '',
            // 删除弹框显示
            del_dialog: false,
            // 删除提示语
            del_tip: '',
            // 删除数据的数组
            del_arr: [],
            // 操作按钮的显示隐藏
            btn_show: false,
            setup_show: false,
            // 表格数据
            tableData: [],
            multipleSelection: [],
            // 分页
            page: 1,
            count: 0,
            max: 10,
            page_show: false,
            // 首页下拉框
            comp_id: '',
            comp_arr: [],
            // 首页输入框
            comp_name: '',
        }
    },
    created () {
        this.user_id = sessionStorage.getItem('id');
        this.lower = sessionStorage.getItem('lower');   //有没有下级
        if (this.lower == 1) {
            this.btn_show = false;
            this.setup_show = false;
        } else {
            this.btn_show = true;
            this.setup_show = true;
        };
    },
    mounted () {
        this.initData()
    },
    watch: {
        // 监听分页数量的变化
        count () {
            if (this.count == (this.page - 1) * this.max && this.count != 0) {
                this.page -= 1;
                this.initData()
            }
        },
    },
    methods: {
        // 子组件传过来的当前页数
        handle_changePage (data) {
            this.page = data;
        },
        // 初始化数据
        initData () {
            getCustomerApi({
                page: this.page,
                name: this.comp_name,   //首页搜索名称
                auth_id: this.user_id,  //用户id
                has_lower: this.lower,  //有没有下级
                lower_id: this.comp_id   //下级公司id
            }).then(res => {
                // console.log(res)
                if (res.status.code == 200) {
                    this.tableData = res.data;
                    this.count = res.count;
                    if (this.count <= 10) {
                        this.page_show = false;
                    } else {
                        this.page_show = true;
                    };
                    this.comp_arr = res.lower_datas.data;
                } else if (res.status.code == 201) {
                    this.$message.error(res.status.msg)
                } else if (res.status.code == 202) {
                    this.$message.error('登录过期，请重新登录！');
                    this.$router.push({ path: '/login' });
                }
            })
        },
        // 表格的方法
        toggleSelection (rows) {
            if (rows) {
                rows.forEach(row => {
                    this.$refs.multipleTable.toggleRowSelection(row);
                });
            } else {
                this.$refs.multipleTable.clearSelection();
            }
        },
        handleSelectionChange (val) {
            this.multipleSelection = val;
        },
        // 弹窗关闭
        handleClose () {
            this.del_dialog = false;
        },
        // 点击导入按钮
        loadtion () {
            if (this.comp_id == '') {
                this.down_id = this.user_id;
            } else {
                this.down_id = this.comp_id;
            };
            this.$router.push({ path: '/customload', query: { lowerId: this.down_id } })
        },
        // 点击添加按钮
        addtion () {
            if (this.comp_id == '') {
                this.down_id = this.user_id;
            } else {
                this.down_id = this.comp_id;
            };
            this.$router.push({ path: '/customadd', query: { id: '添加客户', lowerId: this.down_id } })
        },
        // 点击编辑按钮
        handle_edit (id) {
            if (this.comp_id == '') {
                this.down_id = this.user_id;
            } else {
                this.down_id = this.comp_id;
            };
            this.$router.push({ path: '/customadd', query: { id: id, lowerId: this.down_id } })
        },
        // 单个删除按钮点击
        handle_del (id) {
            this.del_dialog = true;
            this.del_tip = '确定删除此客户？';
            this.del_arr.push(id);
        },
        // 多个删除按钮点击
        deltion () {
            this.del_dialog = true;
            this.del_tip = '确定删除所选中的客户？';
            for (let i = 0; i < this.multipleSelection.length; i++) {
                let del_id = this.multipleSelection[i].uid;
                this.del_arr.push(del_id);
            };
        },
        // 删除弹窗关闭  清空数组
        remove_Fn () {
            this.del_dialog = false;
            this.del_arr.splice(0, this.del_arr.length)
        },
        // 删除弹框点击确定按钮
        Subimt_del () {
            if (this.comp_id == '') {
                this.down_id = this.user_id;
            } else {
                this.down_id = this.comp_id;
            };
            deletCustomerApi({
                id: this.del_arr,
                auth_id: this.user_id,   //用户id
                has_lower: this.lower,   //有没有下级
                lower_id: this.down_id    //下级公司id
            }).then(res => {
                // console.log(res)
                if (res.status.code == 200) {
                    this.$message.success('删除成功');
                    this.del_dialog = false;
                    this.initData();
                } else if (res.status.code == 201) {
                    this.$message.error(res.status.msg)
                } else if (res.status.code == 202) {
                    this.$message.error('登录过期，请重新登录！');
                    this.$router.push({ path: '/login' })
                }
            })
        },
    },
}
  </script>

<style lang="scss" scoped>
.right_main {
    width: 100%;
    height: 100%;
    border-radius: 20px;
    background: #ffffff;
    // padding: 20px;
    //  页面标题  按钮部分
    .top_btn {
        display: flex;
        justify-content: space-between;
        // align-items: center;
        padding: 24px;
        border-bottom: 1px solid #e8e9f1;
        // 页面标题部分
        .page_name {
            display: flex;
            align-items: center;
            .sig {
                border-radius: 2px;
                width: 4px;
                height: 16px;
                background: #6a8be8;
                margin-right: 6px;
            }
            .sig_name {
                font-size: 16px;
                font-weight: 600;
                color: #000000;
            }
            // 下拉框
            .sig_sel {
                margin-left: 24px;
                border-left: 1px solid #d3d3d6;
                padding-left: 24px;
                .el-input {
                    border: none;
                    width: 150px;
                    height: 32px;
                    background: #eaf0fe;
                    border-radius: 8px;
                }
                /deep/.el-input__inner {
                    border: none;
                    width: 150px;
                    height: 32px;
                    background: #eaf0fe;
                    border-radius: 8px;
                }
                /deep/input::-webkit-input-placeholder {
                    color: #20252b;
                }
                /deep/input::-moz-input-placeholder {
                    color: #20252b;
                }
                /deep/input::-ms-input-placeholder {
                    color: #20252b;
                }
            }
        }
        // 按钮部分
        .btn_cl {
            .el-button {
                width: 76px;
                height: 40px;
                font-size: 14px;
                font-weight: 400;
                color: #000000;
                border-radius: 6px;
                border: none;
                background: #eaf0fe;
            }
        }
    }
    // 表格部分
    .tab_list {
        height: 540px;
        background: #ffffff;
        border-radius: 0 0 20px 20px;
        padding: 20px 40px;
        .el-table {
            border-bottom: none;
        }
        /deep/.el-table td,
        .el-table th.is-leaf {
            border-bottom: none;
        }
        /deep/.el-table--border::after,
        .el-table--group::after,
        .el-table::before {
            background: #ffffff;
        }
        // 操作按钮
        span {
            cursor: pointer;
        }
    }
    // 删除弹框
    .el-dialog {
        text-align: center;
        // 标题
        .tipes {
            font-size: 16px;
            font-weight: 600;
            color: #000000;
            text-align: left;
            position: absolute;
            left: 30px;
            top: 30px;
            span {
                border-left: 4px solid #6a8be8;
                padding-left: 6px;
            }
        }
        // 删除弹框
        .del_content {
            font-size: 18px;
            font-weight: 400;
            margin: 50px 0;
        }
        // 提交  重置  按钮部分
        .formBtn {
            margin-bottom: 20px;
            .el-button {
                width: 160px;
                height: 40px;
                font-size: 14px;
                font-weight: 400;
                border-radius: 6px;
            }
            // 确认按钮  删除
            .del_confirm {
                margin-left: 8px;
                background: #6a8be8;
                border: none;
                color: #ffffff;
            }
            .del_Reset {
                background: #ffffff;
                border: 1px solid #d9d9d9;
                color: #20252b;
            }
        }
    }
}
</style>